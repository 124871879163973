.App {
  text-align: center;
}

:root { 
  --black: #000000;
  --blue-ribbon: #004AAD;
  --blue: #004AAD;
  --bright-gray: #40434e;
  --cobalt: #004aad;
  --dove-gray: #707070;
  --green-sheen: #6ec4a7;
  --lemon-chiffon: #fff9cc;
  --lynch: #647f94;
  --malachite: #0af832;
  --pink-swan: #b9b9b9;
  --silver: #bdbdbd;
  --sizzling-sunrise: #ffdf00;
  --torch-red: #f80a1a;
  --vida-loca: #518f18;
  --vida-loca-2: #518f18cc;
  --white: #ffffff;
 
  --font-size-l: 22px;
  --font-size-m: 20px;
  --font-size-s: 18px;
  --font-size-xl: 28px;
  --font-size-xs: 16px;
  --font-size-xxs: 14px;
  --font-size-xxxs: 10px;
}

/*
***************Portugues Plus*******************
*/
.MuiOutlinedInput-notchedOutline {
  border: 1px solid #518f18 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #004aad !important;
}
.MuiTab-wrapper, h4 {
  text-transform: none;
  color: #004aad;
  font-size: 26px;
  font-weight: bold;
}

.tabPill .MuiTab-wrapper {
  font-size: 14px !important;
}
.alunoPills .Mui-selected .MuiTab-wrapper{
  color: #FFDF00 !important;
}

.MuiMenuItem-root, .MuiTypography-body1, .MuiTableCell-root {
  font-size: 16px !important;
  color: #004AAD;
}
.MuiInputLabel-root {
  font-size: 16px !important;
  color: #518F18 !important;
  background-color: white !important;
  padding: 0px 7px 0px 1px !important;
  text-align: center !important;
}
.MuiFormLabel-root.Mui-focused{
  color: #004AAD;
}

.cancelar-btn{
  background-color: #fff !important;
  color: var(--vida-loca) !important;
  padding: 6px 8px o !important;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}
.MuiButton-containedPrimary{
  font-weight: 500 !important;
  color: #ffdf00 !important;
  background-color: #518f18 !important;
  height: 40px !important;
  width: 140px !important;
  border-width: 1px !important;
  border-radius: 10px !important;
  border-color: #ffdf00 !important;
  
}
.MuiButton-containedPrimary:hover{
  font-weight: 700 !important;
  box-shadow: 2px 2px 10px #ffdf00eb !important;
  text-shadow: 1px 1px 6px !important;
}

.MuiButton-containedPrimary:hover .MuiButton-Label{
  text-shadow: 1px 1px 6px !important;
}
.MuiPaper-outlined, .MuiTableContainer-root {
  border: 1px solid #518f18 !important;
  border-radius: 10px !important;
}


.MuiCheckbox-colorSecondary.Mui-checked{
  color: var(--vida-loca) !important;
}
.RaSimpleFormIterator > li > section {
  display: flex;
  justify-content: space-evenly;
}
/* .previews {
  display: none;
} */
.MuiTypography-colorTextPrimary{
  color: #004aad !important;
}

.smallInput{
  width: 150px !important;
  /* margin-right: 2px!important; */
}
.tinyInput{
  width: 50px !important;
  min-width: 40px !important;
}

@media screen and (max-width: 900px) {
  .MuiTab-wrapper {
    font-size: 16px;
  }
  .MuiMenuItem-root, .MuiTypography-body1 {
    font-size: 13px !important;
  }
  .MuiInputLabel-root {
    font-size: 12px !important;
    background-color: white !important;
    padding: 0px 2px !important;
  }
}

/* @media screen and (max-width: 600px) {
  .MuiTab-wrapper {
    font-size: 26px;
  }
  .MuiMenuItem-root, .MuiTypography-body1 {
    font-size: 18px !important;
  }
  .MuiInputLabel-root {
    font-size: 16px !important;
  }
} */

.docTitle {
  color: #40434E !important;
  font-size:16 !important;
}
.fc-button{
  /* background-color: var(--blue) !important; */
  color: var(--blue) !important;
  background-color: var(--white) !important;
  border-color: transparent !important;
}
.fc-toolbar-chunk {
  display: flex;
}
.fc-toolbar-title {
  align-self: center;
  color: var(--blue);
  text-transform: uppercase;
}
.fc-next-button {
  color: var(--blue) !important;
  background-color: var(--white) !important;
  border-color: transparent !important;
}
.fc-prev-button {
  color: var(--blue) !important;
  background-color: var(--white) !important;
  border-color: transparent !important;
}
.RaSimpleFormIterator {
  border-style: solid;
  border-width: 1px;
  border-color: var(--vida-loca);
  border-radius: 10px;
  padding: 10px !important;
}
.MuiButton-root{
  min-width: 20px !important;
}
.myFullWidth{
  display: block;
  width:calc(100vw - 240px);
  overflow: hidden;
}
.list-title, .list-title-blue{
  color: var(--bright-gray);
  font-size:18px;
  font-weight:bold;
  text-transform:capitalize;
}
.list-title-blue{
  color: var(--blue-ribbon);
  text-transform:uppercase;
}
.profe-container{
  display: flex;
  flex-direction: row;
}
.avatar-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.info-container{
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  width:calc(100vw - 440px);
}
.list-actions{
  display: flex;
}
.list-action{
  flex: 1;
  padding: 10px;
}
.list-action:last-child{
  border-left: 1px solid var(--bright-gray);
}
.MuiSelect-select { min-width: 130px !important;}
.desempeno {
  color: #40434E !important;
  font-size: 16px !important;
}
#first-list{
  list-style-type: none;
  margin: 10px;
  padding: 0;
  position: relative;
  transition: all 0.5s linear;
  top:0
}

#first-list:before{
  content: "";
  display: block;
  width: 0;
  height: 100%;
  border:1px dashed var(--bright-gray);
  position: absolute;
  top:0;
  left:30px
}

#first-list li{
  margin: 20px 60px 60px;
  position: relative;
  padding: 10px 20px;
  background:rgba(255, 255, 255, 0.3);
  color:var(--bright-gray);
  border-radius: 10px;
  line-height: 20px;
  width: 90%
}


#first-list li > span{
  content: "";
  display: block;
  width: 0;
  height: 100%;
  border:1px solid var(--vida-loca);
  position: absolute;
  top:0;
  left:-30px
}

#first-list li > span:before,#first-list li > span:after{
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background:var(--vida-loca);
  border:2px solid #fff;
  position: absolute;
  left:-7.5px
}

#first-list li > span:before{top:-10px}
#first-list li > span:after{top:95%}

#first-list .title{
  color: var(--vida-loca) !important;
  font-size: 16px !important;
}

#first-list .info{
    color: var(--blue) !important;
    font-size: 16px !important;
}

#first-list .info:first-letter{text-transform: capitalize;line-height: 1.7}

#first-list .name{
  margin-top: 10px;
  text-transform: capitalize;
  font-style: italic;
  text-align: right;
  margin-right: 20px
}


#first-list .time span{
  position: absolute;
  left: -100px;
  color: var(--bright-gray);
  font-size:80%;
  font-weight: bold;
}
#first-list .time span:first-child{top:-16px}
#first-list .time span:last-child{top:94%}


.arrow{
  position: absolute;
  top: 105%;
  left: 22%;
  cursor: pointer;
height:20px;
width:20px
}

.arrow:hover{
  -webkit-animation: arrow 1s linear infinite;
     -moz-animation: arrow 1s linear infinite;
       -o-animation: arrow 1s linear infinite;
          animation: arrow 1s linear infinite;
}

.cuadro-anotaciones{
  color: white;
    font-size: 24px !important;
}


div.board,
div#react-container {
    height: 500px;
    overflow: hidden;
}
.changeColorPostit{
  position: absolute;
  bottom: -57px;
  background: transparent;
  height: 50px;
  padding-top: 10px;
  padding-left: 10px;
  z-index: -1; 
  border-radius: 30px;
  width: 100%;
}
div.board, div.board-mini {
    background-color: #222222;
    background-image: url('https://portuguesplus.co/interativa/image-2@1x.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

div.board-mini {
  marginTop: 5px;
  height: 80vh;
  background-size: cover;
}

.notes-wrapper {
  width: 100%;
  height: 90%;
  border-top: 1px solid var(--vida-loca);
  padding-top: 5px;
  padding-bottom: 50px;
  padding-left: 20px;
  flex-wrap: wrap;
  display: inline-block;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
}
.line-break {
  width: 100%;
}

div.note {
    /* height: 80px; */
    width: 80%;
    background-color: transparent;
    margin: 2px 0 10px;
    /* position: absolute; */
    cursor: -webkit-grab;
}

div.note:active {
    cursor: -webkit-grabbing;
}

div.note div {
    font-size: 22px;
    padding: 5px;
}

div.note:hover > span {
    opacity: 1;
}

div.note > span {
    position: absolute;
    bottom: 2px;
    right: 2px;
    opacity: 0;
    transition: opacity .25s linear;
}

div.note button {
    margin: 2px;
}

div.note > textarea {
    height: 75%;
    background: rgba(255, 255, 255, .5);
}

.glyphicon-plus {
    position: fixed;
    top: 10px;
    right: 10px;
}

.ql-editor p,
  .ql-editor ol,
  .ql-editor ul,
  .ql-editor pre,
  .ql-editor blockquote,
  .ql-editor h1,
  .ql-editor h2,
  .ql-editor h3,
  .ql-editor h4,
  .ql-editor h5,
  .ql-editor h6 {
   color: #000000;
  }

.tiza { color: white; font-family: 'Rudiment-0gwP';}
.MuiIconButton-colorInherit { color: var(--vida-loca-2) !important}
/*Hide original svg:*/
.tox-tbtn[aria-label="Save"] .tox-icon svg {
  display: none;
}

/*Dimensions of svg icon:*/
.tox-tbtn[aria-label="Save"] .tox-icon {
  display: list-item;
  list-style: none;
  width: 18px;
  height: 18px;
}

/*Svg icon:*/
[aria-label='Save'].tox-tbtn {
  width: 110px !important;
}
/* [aria-label='Save'].tox-tbtn .tox-icon {
  background-image: url("./static/img/tiza.png");
  background-repeat: no-repeat;
  background-position: center center;
} */
[aria-label='Save'].tox-tbtn .tox-icon::after {
  content: '💾'
}
.tox-notifications-container {
  display: none !important;
}

/*EDITOR*/
.floating-menu {
  display: flex;
  background-color: #0D0D0D10;
  padding: 0.2rem;
  border-radius: 0.5rem;
}
.floating-menu button {
  border: none;
  background: none;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0 0.2rem;
  opacity: 0.6;
  /* &:hover,
  &.is-active {
    opacity: 1;
  } */
}

.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;
}
.bubble-menu button {
  border: none;
  background: none;
  color: #FFF;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0 0.2rem;
  opacity: 0.6;

  /* &:hover,
  &.is-active {
    opacity: 1;
  } */
}
.tippy-box{
  max-width: 100% !important;
}
.divider {
  background-color: #000000;
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  width: 2px;
  display: inline;
}
.menu-item {
  background-color: transparent;
  border: none;
  border-radius: 0.4rem;
  color: #0d0d0d;
  height: 1.75rem;
  margin-right: 0.25rem;
  padding: 0.25rem;
  width: 1.75rem;
}
.menu-item svg {
  fill: currentColor;
  height: 100%;
  width: 100%;
}
.menu-item :hover, .menu-item .is-active {
  background-color: #0d0d0d;
  color: #fff;
}

.editor__header {
  /* position: fixed; */
  /* width: 100%; */
  background-color: white;
  z-index: 20000000;
}
.ProseMirror{
  background-color: #fff;
  border: 3px solid #0d0d0d;
  border-radius: 0.75rem;
  color: #0d0d0d;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 230px);
  overflow: auto;
}
.ProseMirror{
  margin-top: 2em;
  padding: 20px;
}
.ProseMirror p{
  font-size: 1rem;
  line-height: 1.625rem;
}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}
.ProseMirror a {
  cursor: pointer;
}
.ProseMirror em {
  font-style: italic;
}
.ProseMirror h4,
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror b,
.ProseMirror strong {
  font-weight: bold;
}
.ProseMirror h4 {
  font-size: 26px;
}
.ProseMirror h3 {
  font-size: 28px;
}
.ProseMirror h2 {
  font-size: 30px;
}
.ProseMirror h1 {
  font-size: 32px;
}
.ProseMirror table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}
.ProseMirror td,
.ProseMirror th {
  border: 2px solid #A8D08D;
  box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  position: relative;
  vertical-align: top;
}
.ProseMirror th {
  background-color: #C5E0B3;
  font-weight: bold;
  text-align: left;
}
.ProseMirror .selectedCell:after {
  background: rgba(200, 200, 255, 0.4);
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}
.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
  z-index: 20000000000000;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
  z-index: 20000000000000;
}

input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 28px !important;
	height: 28px !important;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}

.ProseMirror mark {
  background-color: red;
  border-radius: 0.25em;
  box-decoration-break: clone;
  padding: 0.125em 0;
}

.colores {
  position: absolute;
  background-color: #FFF;
  padding: 10px;
}

/*Classes botões*/
.azul-verde {
  font-weight: 500;
  color: white;
  background-color: #004aad;
  height: 40px;
  width: 140px;
  border-width: 1px;
  border-radius: 10px;
  border-color: white;   
}

.azul-verde:hover {
  font-weight: 600;
  background-color: #518f18;
  color: #ffdf00;
  border-color: #ffdf00;
}

.branco-verde {
  font-weight: 500;
  color: #004aad;
  background-color: #ffffff;
  height: 40px;
  width: 140px;
  border-width: 1px;
  border-radius: 10px;
  border-color: #004aad;  
}

.branco-verde:hover {
  font-weight: 600;
  background-color: #518f18;
  color: #ffdf00;
  border-color: #ffdf00;
}

.branco-azul{
  font-weight: 500;
  color: #004aad;
  background-color: #ffffff;
  height: 40px;
  width: 140px;
  border-width: 1px;
  border-radius: 10px;
  border-color: #004aad;  
}

.branco-azul:hover{
  font-weight: 600;
  background-color: #004AAD;
  color: #ffffff;
  border-color: #ffffff;
}

.destaque-verde{
  font-weight: 500;
  color: #ffdf00;
  background-color: #518f18;
  height: 40px;
  width: 140px;
  border-width: 1px;
  border-radius: 10px;
  border-color: #ffdf00;
}

.destaque-verde:hover{
  font-weight: 700;
  box-shadow: 2px 2px 10px #ffdf00eb;
  text-shadow: 1px 1px 6px;
}


/*Classes cards */ 

/* Card Cursos */

.card-cursos{
  width: 288;
  height: 230;
  margin: '0.5em';
  display: 'inline-block';
  transition: 0.4s !important;
  background-color: #ffffff;
}

.card-cursos:hover{
  background-color: #004AAD;
  color: #ffffff;
  border: #004AAD !important;
}

.card-cursos:hover span, .card-cursos:hover div, .card-cursos:hover a{
  color: #ffdf00 !important;
}

/* Card Alunos */

.card-alunos-a{
  width: 288 !important;
  height: 250 !important;
  margin: '0.5em'!important;
  display: 'inline-block';
  transition: 0.4s !important;
  background-color: #ffffff;
}

.card-alunos-a:hover{
  width: 288 !important;
  height: 250 !important;
  background-color: #004AAD;
  color: #ffffff;
  border: #004AAD !important;
}

.card-alunos-a:hover span, .card-cursos-a:hover div, .card-cursos-a:hover a{
  color: #ffdf00 !important;
}

.card-alunos-b{
  width: 288 !important;
  height: 250 !important;
  margin: '0.5em'!important;
  display: 'inline-block';
  transition: 0.4s !important;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-image: url(./static/img/miniuser.png);
  background-position-x: 15px !important;
  background-position-y: 75px !important;
}

.card-alunos-b:hover{
  width: 288 !important;
  height: 250 !important;
  background-color: #004AAD;
  color: #ffffff;
  border: #004AAD !important;
  background-repeat: no-repeat;
  background-image: url(./static/img/miniuserA.png);
  background-position-x: 15px !important;
  background-position-y: 75px !important;
}

.card-alunos-b:hover span, .card-cursos-b:hover div, .card-cursos-b:hover a{
  color: #ffdf00 !important;
}

/*Classes Menu lateral*/

.menu-agenda{
  color: #004AAD !important;
  background-color: #40434E;
  transition: 0.4s !important;
  background-image: url(./static/img/agenda.png);
  background-position: center left;
  background-position-x: 20px !important;
  background-repeat: no-repeat;
  padding-left: 60px !important;
}

.menu-agenda:hover{
  color: #ffdf00 !important;
  background-color: #004AAD !important;
  background-image: url(./static/img/agendaA.png);
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 60x !important;
}

.menu-alunos{
  color: #004AAD !important;
  background-color: #40434E;
  transition: 0.4s !important;
  background-image: url(./static/img/alumnos.png);
  background-position: center left;
  background-position-x: 20px !important;
  background-repeat: no-repeat;
  padding-left: 60px !important;
}

.menu-alunos:hover{
  color: #ffdf00 !important;
  background-color: #004AAD !important;
  background-image: url(./static/img/alumnosA.png);
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 60x !important;
}

.menu-cursos{
  color: #004AAD !important;
  background-color: #40434E;
  transition: 0.4s !important;
  background-image: url(./static/img/cursos.png);
  background-position: center left;
  background-position-x: 20px !important;
  background-repeat: no-repeat;
  padding-left: 60px !important;
}

.menu-cursos:hover{
  color: #ffdf00 !important;
  background-color: #004AAD !important;
  background-image: url(./static/img/cursosA.png);
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 60x !important;
}

.menu-financeiro{
  color: #004AAD !important;
  background-color: #40434E;
  transition: 0.4s !important;
  background-image: url(./static/img/financiero.png);
  background-position: center left;
  background-position-x: 20px !important;
  background-repeat: no-repeat;
  padding-left: 60px !important;
}

.menu-financeiro:hover{
  color: #ffdf00 !important;
  background-color: #004AAD !important;
  background-image: url(./static/img/financieroA.png);
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 60x !important;
}

.menu-material{
  color: #004AAD !important;
  background-color: #40434E;
  transition: 0.4s !important;
  background-image: url(./static/img/mateiraldidatico.png);
  background-position: center left;
  background-position-x: 20px !important;
  background-repeat: no-repeat;
  padding-left: 60px !important;
}

.menu-material:hover{
  color: #ffdf00 !important;
  background-color: #004AAD !important;
  background-image: url(./static/img/mateiraldidaticoA.png);
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 60x !important;
}

.menu-configuracoes{
  color: #004AAD !important;
  background-color: #40434E;
  transition: 0.4s !important;
  background-image: url(./static/img/config.png);
  background-position: center left;
  background-position-x: 20px !important;
  background-repeat: no-repeat;
  padding-left: 60px !important;
}

.menu-configuracoes:hover{
  color: #ffdf00 !important;
  background-color: #004AAD !important;
  background-image: url(./static/img/configA.png);
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 60x !important;
}

.menu-sair{
  color: #004AAD !important;
  background-color: #40434E;
  transition: 0.4s !important;
  background-image: url(./static/img/ic-power.png);
  background-position: center left;
  background-position-x: 20px !important;
  background-repeat: no-repeat;
  padding-left: 60px !important;
}

.menu-sair:hover{
  color: #ffdf00 !important;
  background-color: #004AAD !important;
  background-image: url(./static/img/ic-powerA.png);
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 60x !important;
}

/* estilo telefones */

.estilo-telefones{
  background-repeat: no-repeat;
  background-image: url(./static/img/whatsapp.png) !important;
  background-image: url(./static/img/telefono.png) !important;
}

.estilo-telefones:hover{
  background-repeat: no-repeat;
  background-image: url(./static/img/whatsapp.png) !important;
  background-image: url(./static/img/telefonoA.png) !important;
}

.financiero-cell{
  font-size: 12px !important;
}